import flatpickr from 'flatpickr';
import moment from 'moment-timezone';
import { Calendar } from 'fullcalendar';
import { Controller } from '@hotwired/stimulus';
import { findSimilarColors } from '@/utils/color';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

export default class extends Controller {
  static targets = [
    'form',
    'modal',
    'date',
    'startTime',
    'endTime',
    'service',
    'customer',
    'note',
    'submit',
  ];

  static values = {
    offset: String,
    timezone: String,
  };

  connect() {
    this.csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    this.initCalendar();
    this.setUpFormNewEvent();
  }

  initCalendar() {
    const calendarElement = document.getElementById('full-calendar');

    const calendar = new Calendar(calendarElement, {
      selectable: true,
      plugins: [dayGridPlugin, timeGridPlugin],
      initialView: 'timeGridWeek',
      timeZone: this.timezoneValue,
      height: window.innerHeight - 64 - 32,
      customButtons: {
        customPlusButton: {
          text: '+',
          click: () => {
            this.closeModalCreateAppointment();
            this.openForm();
          },
        },
      },
      headerToolbar: {
        right: 'customPlusButton',
        left: 'prev title next',
        center: 'timeGridDay,timeGridWeek,dayGridMonth',
      },
      views: {
        timeGridDay: {
          scrollTime: '08:00:00',
          dayHeaderFormat: {
            weekday: 'short',
            day: '2-digit',
          },
          slotLabelFormat: {
            hour: 'numeric',
          },
          eventContent: ({ event }) => {
            const { start, end } = this.formatTimeInTimezone(event);
            const duration = end.diff(start, 'minutes');

            if (event.allDay) {
              return this.renderEventHTML(event, 'day');
            } else {
              return this.renderEventHTML(event, 'day', start, end, duration);
            }
          },
          datesSet: () => {
            const offsetEl = $('.fc-timegrid-axis-frame').first();

            if (this.offsetValue.length > 2) {
              offsetEl.addClass('!text-[8px]');
            }
            offsetEl.html(`GMT ${this.offsetValue}`);
          },
        },
        timeGridWeek: {
          titleFormat: { month: 'long', year: 'numeric' },
          slotDuration: '00:15:00',
          scrollTime: '08:00:00',
          slotLabelInterval: '01:00',
          dayMaxEvents: 5,
          dayHeaderFormat: {
            weekday: 'short',
            day: '2-digit',
          },
          slotLabelFormat: {
            hour: 'numeric',
          },
          eventContent: ({ event }) => {
            const { start, end } = this.formatTimeInTimezone(event);
            const duration = end.diff(start, 'minutes');

            if (event.allDay) {
              return this.renderEventHTML(event, 'week');
            } else {
              return this.renderEventHTML(event, 'week', start, end, duration);
            }
          },
          datesSet: () => {
            const offsetEl = $('.fc-timegrid-axis-frame').first();

            if (this.offsetValue.length > 2) {
              offsetEl.addClass('!text-[8px]');
            }
            offsetEl.html(`GMT ${this.offsetValue}`);
          },
        },
        dayGridMonth: {
          dayMaxEventRows: 2,
          dayCellContent: (args) => {
            const html = args.isToday
              ? `<div class="number-day today-number-wrapper">${args.dayNumberText}</div>`
              : `<div class="number-day">${args.dayNumberText}</div>`;

            return {
              html,
            };
          },
          eventContent: ({ event }) => {
            const { start, end } = this.formatTimeInTimezone(event);

            return {
              html: `
                    <div class="fc-event-time">${start.format('HH:mm')} - ${end.format('HH:mm')}</div>
                    <div class="fc-event-title title-small">${event.title}</div>
                  `,
            };
          },
          dayCellDidMount: function (info) {
            if (info.date.getMonth() !== calendar.getDate().getMonth()) {
              info.el.style.backgroundColor = '#F2F2F2';
            }
          },
        },
      },
      events: this.fetchAppointments(),
      eventClick: this.handleEventClick.bind(this),
      dateClick: this.handleEventDateClick.bind(this),
      select: this.handleEventSelect.bind(this),
      eventDidMount: (info) => {
        info.el.style.color = 'black';
        info.el.style.borderLeft = `3px solid ${info.event.extendedProps.borderColorLeft}`;
      },
    });

    this.calendar = calendar;

    this.calendar.render();

    $('.fc-prev-button').on('click', () => {
      const date = this.calendar.getDate();
      this.fetchAppointments({ date });
    });

    $('.fc-next-button').on('click', () => {
      const date = this.calendar.getDate();
      this.fetchAppointments({ date });
    });

    $('.fc-timeGridDay-button').on('click', () => {
      this.calendar.changeView('timeGridDay');
    });
  }

  renderEventHTML(event, grid, start, end, duration) {
    const titleHTML = `
      <div class="pl-1">
        <div class="text-black title-small whitespace-nowrap text-ellipsis overflow-hidden pr-2">${event.title}</div>
        ${!event.allDay && ((grid == 'day' && duration >= 45) || (grid == 'week' && duration >= 30)) ? this.renderTimeHTML(start, end) : ''}
      </div>
    `;
    return { html: titleHTML };
  }

  renderTimeHTML(start, end) {
    return `
      <div class="text-grey-800 title-small font-normal whitespace-nowrap">
        ${start.format('HH:mm')} - ${end.format('HH:mm')}
      </div>
    `;
  }

  addEvents(appointments) {
    appointments.forEach((appointment) => {
      if (!this.calendar.getEventById(appointment.id)) {
        this.calendar.addEvent({
          id: appointment.id,
          title: appointment.title,
          start: appointment.start,
          end: appointment.end,
          color: findSimilarColors(appointment.color),
          textColor: '#01111D',
          allDay: appointment.all_day,
          extendedProps: {
            start_raw: appointment.start_raw,
            end_raw: appointment.end_raw,
            borderColorLeft: appointment.color,
          },
        });
      }
    });
  }

  async fetchAppointments(options = {}) {
    try {
      const response = await fetch(`/calendar/appointments_list`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter: options,
        }),
      });

      if (response.ok) {
        const appointments = await response.json();
        this.addEvents(appointments);
      }
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    }
  }

  handleEventClick(e) {
    fetch(`/business/bookings/${e.event.id}/booking_modal`)
      .then((response) => response.text())
      .then((html) => {
        document.getElementById('booking-container').innerHTML = html;
        document.getElementById('booking-modal').open = true;
        this.setUpFormNewEvent();
      });
  }

  handleEventDateClick(e) {
    $('.conflict-appointment').addClass('hidden').html('');
    const options = this.formatEventDateTime(e.dateStr);
    if (e.allDay) options.endTime = '23:59';

    this.openForm(options);
  }

  handleEventSelect(e) {
    const options = this.formatEventDateTime(e.startStr);
    options.endTime = moment(e.endStr).format('HH:mm');

    if (e.allDay) options.endTime = '23:59';

    this.openForm(options);
  }

  openForm(options = {}) {
    let { date, startTime, endTime } = options;

    if (date === undefined) {
      const today = new Date();

      date = today;
      startTime = moment(today).format('HH:mm');
      endTime = moment(today).add(1, 'hour').format('HH:mm');
    }

    date = moment(new Date(date)).format('DD MMM, YYYY');
    const flatpickrInstance = flatpickr(this.dateTarget, {
      dateFormat: 'd M, Y',
      disableMobile: true,
    });

    flatpickrInstance.setDate(date, true);

    this.startTimeTarget.value = startTime;
    this.endTimeTarget.value = endTime;

    this.modalTarget.open = true;
  }

  formatEventDateTime(date) {
    const formattedDate = moment(date);
    const startTime = moment.tz(date, this.timezoneValue).format('HH:mm');
    const endTime = moment
      .tz(date, this.timezoneValue)
      .add(1, 'hour')
      .format('HH:mm');

    return {
      date: formattedDate,
      startTime,
      endTime,
    };
  }

  setUpFormNewEvent() {
    $('body').on('click', '.booking-edit', () => {
      $('#booking-show').addClass('hidden');
      $('#booking-edit').removeClass('hidden');
      $('.booking-title').html('Book appointment');
      $('.booking-edit').addClass('hidden');
      $('.booking-cancel').addClass('hidden');
    });

    $('body').on('change', '.booking-service', ({ target }) => {
      const $target = $(target);
      const $form = $target.closest('form');

      if ($target.val() === '') return;

      $.ajax({
        url: `/business/services/${$target.val()}`,
        type: 'GET',
        dataType: 'json',
        success: (response) => {
          const startTime = $form.find('#booking_start_date').val();
          const duration = response.duration + response.buffer_time;

          const endTime = moment(startTime, 'HH:mm')
            .add(duration, 'minutes')
            .format('HH:mm');

          $form.find('#booking_end_date').val(endTime).trigger('change');
          this.validateSubmit();
        },
      });
    });

    $('body').on('change', '.booking-service, .booking-customer', () => {
      this.validateSubmit();
    });

    $('body').on('input', '.booking-note', ({ target }) => {
      const $target = $(target);
      const numberNote = $target
        .closest('.note-container')
        .find('.number-note');

      numberNote.html(`${$target.val().length}/100`);
    });
  }

  formatTimeInTimezone(event) {
    const startRaw = event._def.extendedProps.start_raw;
    const endRaw = event._def.extendedProps.end_raw;

    return {
      start: moment.tz(startRaw, this.timezoneValue),
      end: moment.tz(endRaw, this.timezoneValue),
    };
  }

  close() {
    this.modalTarget.open = false;
    this.closeModalCreateAppointment();
    document.getElementById('booking-container').innerHTML = '';
  }

  closeModalCreateAppointment() {
    const form = $('#new_booking');
    const service = form.find('.booking-service');
    const customer = form.find('.booking-customer');
    const note = form.find('.booking-note');

    $('.conflict-appointment').html('');
    $('.conflict-appointment').addClass('hidden');
    note.val('');
    service.val('').trigger('change');
    customer.val('').trigger('change');
    form.parsley().reset();
  }

  validateSubmit() {
    const note = $(this.noteTarget);
    const startTime = $(this.startTimeTarget);
    const endTime = $(this.endTimeTarget);
    const service = $(this.serviceTarget);
    const date = $(this.dateTarget);

    // validate end time
    endTime.attr('data-parsley-greaterorequaltime', startTime.val());
    endTime.parsley().validate();
    const isValidEndTime = endTime.parsley().isValid();

    // validate note
    note.parsley().validate();
    const isValidNote = note.parsley().isValid();

    // validate input fields
    const targets = [
      this.serviceTarget.value,
      this.customerTarget.value,
      this.dateTarget.value,
      this.startTimeTarget.value,
      this.endTimeTarget.value,
    ];

    const isEmpty = targets.some((target) => target === '');

    // validate conflict appointment

    if (!isEmpty) {
      fetch(`/calendar/appointments_list`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter: {
            date: this.dateTarget.value,
            service_ids: this.serviceTarget.value,
            // customer_ids: this.customerTarget.value,
            start_time: this.startTimeTarget.value,
            end_time: this.endTimeTarget.value,
          },
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.length === 0) {
            $('.conflict-appointment').addClass('hidden').html('');
            this.submitTarget.disabled = false;
            return;
          }

          const { dataset } = this.formTarget;
          const { id, customer_name, title } = response[0];

          if (id == dataset.appointmentId) {
            $('.conflict-appointment').addClass('hidden').html('');
            this.submitTarget.disabled = false;
            return;
          }

          const responseStart = moment(response[0].start);
          const responseEnd = moment(response[0].end);
          const inputStartTime = startTime.val();
          const inputEndTime = endTime.val();

          if (
            response[0].service === service.val() &&
            responseStart.format('DD MMM, YYYY') === date.val() &&
            responseStart.format('HH:mm') === inputStartTime &&
            responseEnd.format('HH:mm') === inputEndTime
          ) {
            const html = document.createElement('p');
            html.classList.add('font-normal');

            html.innerHTML = `
            <span class="font-bold">${customer_name}</span>
            <span> has a</span>
            <span class="font-bold"> ${title}</span>
            <span> appointment on</span>
            <span class="font-bold"> ${this.dateTarget.value} - ${inputStartTime} - ${inputEndTime}.</span>
            <span> Are you sure you wish to continue with the booking?</span>
          `;

            $('.conflict-appointment').removeClass('hidden').html(html);
            this.submitTarget.disabled = true;
          }
        });
    }

    // enable submit button
    this.submitTarget.disabled = !(isValidEndTime && isValidNote && !isEmpty);
  }
}
